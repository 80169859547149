<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Clienti </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Aggiungi cliente</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h2 class="text-primary-light">Aggiungi nuovo cliente</h2>

        <b-row class="my-2">

        <b-col md="12">
             <b-form-group label="Tipologia Cliente" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="radio-group-2"
        v-model="cliente_tipologia"
        :aria-describedby="ariaDescribedby"
        name="radio-sub-component"
      >
        <b-form-radio value="privato">Privato</b-form-radio>
        <b-form-radio value="azienda">Azienda</b-form-radio>
      </b-form-radio-group>
        </b-form-group>
        </b-col>

          <b-col md="4">
            <b-form-group label="Nome">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Cognome">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="cliente_tipologia == 'privato'">
            <b-form-group label="Sesso">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Codice Fiscale">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Luogo Di Nascita">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Data Di Nascita">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Ragione Sociale">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right">
          <b-button class="w-25" variant="primary"> Aggiungi </b-button>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BFormInput,
  },

  data: () => ({
      cliente_tipologia: null,
  }),
};
</script>
